<template v-if="Object.key(invoice).length">
    <div class="table-responsive" v-if="invoice.order">
        <table class="table table-bordered mt-7 mb-7">
            <tr v-if="invoice.order">
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.order.id') }}</th>
                <td>{{ invoice.order.id }}</td>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.order.date') }}</th>
                <td>{{ $moment(invoice.order.created_at).format("DD.MM.YYYY - HH:mm:ss") }}</td>
            </tr>

            <template v-if="invoice.user && invoice.address">
                <tr>
                    <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.address.invoiceType') }}</th>
                    <td>{{ getInvoiceTypeText(invoice.address.invoice_type_id) }}</td>
                    <template v-if="invoice.address.invoice_type_id == 2">
                        <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.address.companyName') }}</th>
                        <td>{{ invoice.address.company_name ?? "-" }}</td>
                    </template>
                    <template v-else>
                        <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.user.fullName') }}</th>
                        <td>{{ invoice.user.full_name }}</td>
                    </template>
                </tr>
                <tr>
                    <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.address.companyTaxOffice') }}</th>
                    <td>{{ invoice.address.company_tax_office ?? '-' }}</td>
                    <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.user.identificationNumber') }}</th>
                    <td>{{ invoice.address.identification_number ?? '-' }}</td>
                </tr>
                <tr>
                    <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.user.state') }}</th>
                    <td>{{ invoice.address.state && invoice.address.state.name ? invoice.address.state.name : '-' }}</td>
                    <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.user.city') }}</th>
                    <td>{{ invoice.address.city && invoice.address.city.name ? invoice.address.city.name : '-' }}</td>
                </tr>
                <tr>
                    <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.user.district') }}</th>
                    <td colspan="3">{{ invoice.address.district ?? '-' }}</td>
                </tr>
                <tr>
                    <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.user.address') }}</th>
                    <td colspan="3">{{ invoice.address.address ?? '-' }}</td>
                </tr>
            </template>
        </table>
    </div>

    <div class="table-responsive" v-if="invoice.items">
        <table class="table table-bordered mb-7" >
            <tr>
                <th>#</th>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.item.id') }}</th>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.item.name') }}</th>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.item.unitPrice') }}</th>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.item.quantity') }}</th>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.item.tax') }}</th>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.item.taxes') }}</th>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.item.total') }}</th>
            </tr>

            <tr v-for="(item, itemIndex) in invoice.items" :key="itemIndex">
                <td>{{ itemIndex + 1 }}</td>
                <td>{{ item.id }}</td>
                <td>{{ item.title }} {{ item.variant_combination_text }}</td>
                <td>{{ item.unit_price }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.tax }}</td>
                <td>
                    {{ item.taxes.length ? arrayColumn(item.taxes, 'tax_rate').join(', ') : '-' }}
                </td>
                <td>{{ item.total }}</td>
            </tr>

            <template v-if="!(invoice.discount_coupons.length) && invoice.total">
                <tr>
                    <th colspan="5">{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.total.subTotal') }}</th>
                    <td colspan="3" class="text-end">{{ invoice.total.subtotal }}</td>
                </tr>
                <tr>
                    <th colspan="5">{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.total.tax') }}</th>
                    <td colspan="3" class="text-end">{{ invoice.total.tax }}</td>
                </tr>
                <tr>
                    <th colspan="5">{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.total.total') }}</th>
                    <td colspan="3" class="text-end">{{ invoice.total.total }}</td>
                </tr>
            </template>
        </table>
    </div>

    <div class="table-responsive" v-if="invoice.discount_coupons && invoice.discount_coupons.length">
        <table class="table table-bordered mb-7">
            <tr>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.discountCoupon.code') }}</th>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.discountCoupon.type') }}</th>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.discountCoupon.value') }}</th>
                <th>{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.discountCoupon.total') }}</th>
            </tr>

            <tr v-for="(discountCoupon, discountCouponIndex) in invoice.discount_coupons" :key="discountCouponIndex">
                <td>{{ discountCoupon.discount_coupon.code }}</td>
                <td>{{ $t('pages.ecommerce.discountCoupon.couponTypes.' + types[discountCoupon.discount_coupon.discount_type]) }}</td>
                <td>{{ discountCoupon.discount_coupon.discount_value }}</td>
                <td>{{ discountCoupon.discount_total }}</td>
            </tr>

            <template v-if="invoice.total">
                <tr>
                    <th colspan="2">{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.total.subTotal') }}</th>
                    <td colspan="2" class="text-end">{{ invoice.total.subtotal }}</td>
                </tr>
                <tr>
                    <th colspan="2">{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.total.tax') }}</th>
                    <td colspan="2" class="text-end">{{ invoice.total.tax }}</td>
                </tr>
                <tr>
                    <th colspan="2">{{ $t('pages.ecommerce.invoiceManagement.invoice.preview.cols.total.total') }}</th>
                    <td colspan="2" class="text-end">{{ invoice.total.total }}</td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
export default {
    layout: 'preview',
    name: "_id",
    data() {
        return {
            invoice: {},
            types: {
                1: "static",
                2: "percent",
            }
        }
    },
    computed: {
        invoiceID() {
            return this.$route.params.id;
        }
    },
    created() {
        if (!this.invoiceID || !(this.invoiceID > 0)) {
            this.$router.push({
                path: "/ecommerce/invoice-management/invoice"
            });
        }
    },
    mounted() {
        if (this.invoiceID && this.invoiceID > 0) {
            this.loadInvoicePreview();
        }
    },
    methods: {
        loadInvoicePreview() {
            this.axios.get(this.endpoints['ecommerce_invoice_preview'] + '/' + this.invoiceID, {
                params: {
                    discount_distribute: true
                }
            }).then((response) => {
                let data = response.data.data;
                this.invoice = data;
            })
        },
        getInvoiceTypeText(id) {
            let types = {
                1: this.$t('common.individual'),
                2: this.$t('common.corporate')
            };

            return types[id];
        },
    }
}
</script>

<style>
table tr td, table tr th {
    padding: .75rem !important;
}

table tr th {
    font-weight: 700 !important;
    color: #333 !important;
}

.table-bordered {
    border: 1px solid #dee2e6;
}
</style>